var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper-content',{attrs:{"step":"3"}},[(_vm.step === 3)?_c('v-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"warning lighten-1 mb-5"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.onOpenAddressBook('sender')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-book-open-variant")]),_vm._v("From address book")],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.onOpenAddressBook('receiver')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-book-open-variant")]),_vm._v("From address book")],1)],1)],1),(_vm.countryFrom && _vm.countryTo)?_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","filled":"","background-color":"white","label":_vm.countryFrom.title,"disabled":""}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","filled":"","background-color":"white","label":((_vm.cityFrom.city) + " (" + (_vm.cityFrom.postal_code) + ")"),"disabled":""}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","filled":"","background-color":"white","label":_vm.countryTo.title,"disabled":""}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","filled":"","background-color":"white","label":((_vm.cityTo.city) + " (" + (_vm.cityTo.postal_code) + ")"),"disabled":""}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"First name*","error-messages":errors},model:{value:(_vm.source.firstName),callback:function ($$v) {_vm.$set(_vm.source, "firstName", $$v)},expression:"source.firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Last name*","error-messages":errors},model:{value:(_vm.source.lastName),callback:function ($$v) {_vm.$set(_vm.source, "lastName", $$v)},expression:"source.lastName"}})]}}],null,true)}),_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Company"},model:{value:(_vm.source.company),callback:function ($$v) {_vm.$set(_vm.source, "company", $$v)},expression:"source.company"}}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Email*","error-messages":errors},model:{value:(_vm.source.email),callback:function ($$v) {_vm.$set(_vm.source, "email", $$v)},expression:"source.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Phone*","type":"phone","error-messages":errors},model:{value:(_vm.source.phone),callback:function ($$v) {_vm.$set(_vm.source, "phone", $$v)},expression:"source.phone"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Address 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Address 1*","error-messages":errors},model:{value:(_vm.source.address1),callback:function ($$v) {_vm.$set(_vm.source, "address1", $$v)},expression:"source.address1"}})]}}],null,true)}),_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Address 2"},model:{value:(_vm.source.address2),callback:function ($$v) {_vm.$set(_vm.source, "address2", $$v)},expression:"source.address2"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Address 3"},model:{value:(_vm.source.address3),callback:function ($$v) {_vm.$set(_vm.source, "address3", $$v)},expression:"source.address3"}}),_c('div',[_c('v-checkbox',{staticClass:"float-right",attrs:{"color":"orange lighten-5","label":(_vm.shipment.shipFromAddressBookId) ? 'Update this address in my address book' : 'Save this address in my address book'},model:{value:(_vm.source.save),callback:function ($$v) {_vm.$set(_vm.source, "save", $$v)},expression:"source.save"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"First name*","error-messages":errors},model:{value:(_vm.destination.firstName),callback:function ($$v) {_vm.$set(_vm.destination, "firstName", $$v)},expression:"destination.firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Last name*","error-messages":errors},model:{value:(_vm.destination.lastName),callback:function ($$v) {_vm.$set(_vm.destination, "lastName", $$v)},expression:"destination.lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Company*","error-messages":errors},model:{value:(_vm.destination.company),callback:function ($$v) {_vm.$set(_vm.destination, "company", $$v)},expression:"destination.company"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Email*","error-messages":errors},model:{value:(_vm.destination.email),callback:function ($$v) {_vm.$set(_vm.destination, "email", $$v)},expression:"destination.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Phone*","error-messages":errors},model:{value:(_vm.destination.phone),callback:function ($$v) {_vm.$set(_vm.destination, "phone", $$v)},expression:"destination.phone"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Address 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Address 1*","error-messages":errors},model:{value:(_vm.destination.address1),callback:function ($$v) {_vm.$set(_vm.destination, "address1", $$v)},expression:"destination.address1"}})]}}],null,true)}),_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Address 2"},model:{value:(_vm.destination.address2),callback:function ($$v) {_vm.$set(_vm.destination, "address2", $$v)},expression:"destination.address2"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":"","background-color":"white","label":"Address 3"},model:{value:(_vm.destination.address3),callback:function ($$v) {_vm.$set(_vm.destination, "address3", $$v)},expression:"destination.address3"}}),_c('div',[_c('v-checkbox',{staticClass:"float-right",attrs:{"color":"orange lighten-5","label":(_vm.shipment.shipToAddressBookId) ? 'Update this address in my address book' : 'Save this address in my address book'},model:{value:(_vm.destination.save),callback:function ($$v) {_vm.$set(_vm.destination, "save", $$v)},expression:"destination.save"}})],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.handleContinue}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" Back ")])],1)],1)],1)]}}],null,false,1538561785)}),_c('AddressBookModal',{on:{"select":_vm.onAddressSelected},model:{value:(_vm.addressModal),callback:function ($$v) {_vm.addressModal=$$v},expression:"addressModal"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-stepper-content step="5">
    <v-container fluid v-if="!loading && step===5">
      <ValidationObserver v-slot="{invalid}">
        <v-form>
          <v-row>
            <v-col cols="8" lg="6">
              <v-card class="warning lighten-1 mb-5">
                <v-card-title style="font-size: 1.3rem" class="text-center font-weight-light mb-5 pt-5 justify-center">
                  Shipment details
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>From:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ cityFrom.city }} ({{ cityFrom.postal_code }}), {{ countryFrom.title }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>To:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ cityTo.city }} ({{ cityTo.postal_code }}), {{ countryTo.title }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Pickup date:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ shipment.date }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="shipment.pickup === 'pick-up'">
                      <v-list-item-content>Pickup time:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ shipment.pickupEarliest }} - {{ shipment.pickupLatest }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <h2 class="text-center my-5 font-weight-regular">Cost</h2>
              <!--
                <v-text-field
                  label="Coupon code"
                  class="d-flex align-baseline justify-center"
                  hint="If you have a discount coupon code enter it here"
                  persistent-hint
                  outlined
                  dense
              >
                <template v-slot:append-outer>
                  <v-btn
                      color="warning"
                      class="mt-n2">
                    Apply coupon
                  </v-btn>
                </template>
              </v-text-field> -->
              <v-list dense class="cost mt-5">
                <v-list-item v-for="(item, key) in selectedRate.fees" :key="key">
                  <v-list-item-content>{{item.title}}:</v-list-item-content>
                  <v-list-item-content class="dotted flex-grow-1"/>
                  <v-list-item-content class="align-end">
                    {{ 'GBP' | currency }}{{ item.total_cost }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Total:</v-list-item-content>
                  <v-list-item-content class="dotted flex-grow-1"/>
                  <v-list-item-content class="align-end">
                    {{ selectedRate.cost_currency | currency }}{{ selectedRate.total_cost }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <h2 class="my-5 font-weight-regular">Payment method</h2>-->
              <div>
              <!-- We don't use it at the moment
                <ValidationProvider name="paymentMethod" rules="required" v-slot="{errors}">
                  <v-radio-group
                      class="mx-auto"
                      v-model="paymentMethod"
                      :error-messages="errors"
                  >
                    <v-radio
                        label="Paypal."
                        value="paypal"
                    ></v-radio>
                    <v-radio
                        label="Credit"
                        value="credit"
                    ></v-radio>
                  </v-radio-group>
                </ValidationProvider>
                <v-divider/> -->
                <ValidationProvider name="agreeTerms" rules="required" v-slot="{errors}">
                  <v-checkbox class="mt-5 mb-0" :error-messages="errors" v-model="agreeTerms">
                    <template v-slot:label>
                      <span>I agree to the <a href="#">Terms and Conditions of D2D</a>.</span>
                    </template>
                  </v-checkbox>
                </ValidationProvider>
                <ValidationProvider name="agreeOpen" rules="required" v-slot="{errors}">
                  <v-checkbox
                      class="my-0"
                      v-model="agreeOpen"
                      label="I understand I need to leave my package open for inspection by the courier driver."
                      :error-messages="errors"/>
                </ValidationProvider>
              </div>
            </v-col>
            <v-col cols="4" lg="2" class="ml-10">
              <h2 class="mb-5 text-center font-weight-regular text-no-wrap">Booking summary</h2>
              <v-img :src="courierLogos[selectedRate.shipper_account.courier]"/>
              <h3 class="text-center font-weight-regular blue--text">{{ selectedRate.shipper_account.title }}</h3>
              <h2 class="my-5 text-center font-weight-regular text-no-wrap">Cost:
                {{ selectedRate.cost_currency | currency }}{{ selectedRate.total_cost }}</h2>
              <v-divider/>
              <h2 class="my-5 text-center font-weight-regular text-no-wrap">Questions?</h2>
              <p>Do you have any questions about how to complete your booking? Call us now for assistance on the
                telephone
                number displayed below.</p>
              <h2 class="my-5 text-center font-weight-regular text-no-wrap blue--text">08444 930 694</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                  color="primary"
                  class="mr-3"
                  @click="processShipment"
                  :disabled="invalid">
                Process shipment
              </v-btn>
              <v-btn
                  text
                  @click="$emit('back')"
              >
                Back
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-stepper-content>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import {courierLogos} from "@/choices";
import Rate from "@/models/Rate";
import Suburb from "@/models/Suburb";
import Country from "@/models/Country";
import Order from "@/models/Order";
import moment from "moment";

export default {
  props: ['step'],
  data: function () {
    return {
      vatPercent: 0.1,
      agreeTerms: '',
      agreeOpen: '',
      paymentMethod: '',
      overlay: false,
      loading: false,
      courierLogos
    }
  },
  methods: {
    ...mapMutations("shipping", {setCreatingShipment: 'setCreatingShipment'}),
    async processShipment() {

      const orderParams = {
        ship_date: this.shipment.date || moment().format('YYYY-MM-DD'),
        shipment: {
          ship_from: {
            country: this.countryFrom.code,
            contact_name: `${this.shipment.source.firstName} ${this.shipment.source.lastName}`,
            phone: this.shipment.source.phone,
            fax: '',
            email: this.shipment.source.email,
            company_name: this.shipment.source.company,
            street1: this.shipment.source.address1,
            street2: this.shipment.source.address2,
            street3: this.shipment.source.address3,
            city: this.cityFrom.city,
            state: this.cityFrom.state || '',
            postal_code: this.cityFrom.postal_code,
          },
          ship_to: {
            country: this.countryTo.code,
            contact_name: `${this.shipment.destination.firstName} ${this.shipment.destination.lastName}`,
            phone: this.shipment.destination.phone,
            fax: '',
            email: this.shipment.destination.email,
            company_name: this.shipment.destination.company,
            street1: this.shipment.destination.address1,
            street2: this.shipment.destination.address2,
            street3: this.shipment.destination.address3,
            city: this.cityTo.city,
            state: this.cityTo.state || '',
            postal_code: this.cityTo.postal_code,
          },
          return_to: null,
          delivery_instructions: this.shipment.comments,
          description: this.shipment.description,
          parcels: this.shipment.packages.map(box => (
            {
              width: box.width,
              height: box.height,
              depth: box.depth,
              weight: box.weight,
              is_document: box.is_document,
              items: box.items ? box.items.map(item => ({
                description: item.description,
                quantity: item.quantity,
                price: item.value,
                price_currency: item.price_currency,
                origin_country: item.country,
                hs_code: item.hs_code,
                weight: item.weight
              })) : []
            }
          )),
        },
        selected_rate: this.shipment.selectedRate,
        insurance_for_customer: (this.shipment.insurance) ? "15.00" : "0.00",
        customs: {
          purpose: this.shipment.reasonOfExport ? this.shipment.reasonOfExport : null,
          terms_of_trade: this.shipment.termsOfTrade ? this.shipment.termsOfTrade : null
        },
        billing: {}
      }

      if (this.shipment.pickup === 'pick-up') {
        orderParams.pickup_information = {
          "time_earliest": this.shipment.pickupEarliest,
          "time_latest": this.shipment.pickupLatest,
          "date": this.shipment.date
        }
      }

      this.overlay = true;

      try {
        const {response} = await Order.api().createOrder(orderParams);
        const orderId = response.data.id;
        await Order.api().ship(orderId);
        await this.$router.push({name: 'Shipment', params: {id: orderId}})
      } catch (e) {
        this.overlay = false;
        throw e
      }
    }
  },
  computed: {
    ...mapState("shipping", {shipment: 'creatingShipment'}),
    selectedRate() {
      return Rate.query().with('shipper_account').find(this.shipment.selectedRate)
    },
    countryFrom() {
      return Country.find(this.shipment.countryFrom)
    },
    countryTo() {
      return Country.find(this.shipment.countryTo)
    },
    cityFrom() {
      return Suburb.find(this.shipment.cityFrom)
    },
    cityTo() {
      return Suburb.find(this.shipment.cityTo)
    }
  }
}
</script>

<style scoped>
.dotted {
  border-bottom: 1px grey dotted;
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>
<template>
  <v-stepper-content step="3">
    <v-container fluid v-if="step === 3">
      <ValidationObserver v-slot="{invalid}">
        <v-form>
          <v-row>
            <v-col cols="auto">
              <v-card class="warning lighten-1 mb-5">
                <v-card-text>
                  <v-row>
                    <v-col cols="6" class="text-right">
                      <v-btn small color="warning" @click="onOpenAddressBook('sender')"><v-icon small class="mr-2">mdi-book-open-variant</v-icon>From address book</v-btn>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn small color="warning" @click="onOpenAddressBook('receiver')"><v-icon small class="mr-2">mdi-book-open-variant</v-icon>From address book</v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="countryFrom && countryTo">
                    <v-col>
                      <v-autocomplete outlined filled background-color="white" :label="countryFrom.title" disabled/>
                    </v-col>
                    <v-col>
                      <v-autocomplete outlined filled background-color="white" :label="`${cityFrom.city} (${cityFrom.postal_code})`" disabled/>
                    </v-col>
                    <v-col cols="auto">
                      <v-divider vertical/>
                    </v-col>
                    <v-col>
                      <v-autocomplete outlined filled background-color="white" :label="countryTo.title" disabled/>
                    </v-col>
                    <v-col>
                      <v-autocomplete outlined filled background-color="white" :label="`${cityTo.city} (${cityTo.postal_code})`" disabled/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="First name" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="First name*"
                            :error-messages="errors"
                            v-model="source.firstName"/>
                      </ValidationProvider>
                      <ValidationProvider name="Last name" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Last name*"
                            :error-messages="errors"
                            v-model="source.lastName"/>
                      </ValidationProvider>
                      <v-text-field
                          dense
                          outlined
                          filled
                          background-color="white"
                          label="Company"
                          v-model="source.company"/>
                      <ValidationProvider name="Email" rules="required|email" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Email*"
                            :error-messages="errors"
                            v-model="source.email"/>
                      </ValidationProvider>
                      <ValidationProvider name="Phone" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Phone*"
                            type="phone"
                            :error-messages="errors"
                            v-model="source.phone"/>
                      </ValidationProvider>
                      <ValidationProvider name="Address 1" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Address 1*"
                            :error-messages="errors"
                            v-model="source.address1"
                          />
                      </ValidationProvider>
                      <v-text-field
                          dense
                          outlined
                          filled
                          background-color="white"
                          label="Address 2"
                          v-model="source.address2"/>
                      <v-text-field
                          dense
                          outlined
                          filled
                          background-color="white"
                          label="Address 3"
                          v-model="source.address3"/>
                      <div>
                        <v-checkbox
                            class="float-right"
                            color="orange lighten-5"
                            :label="(shipment.shipFromAddressBookId) ? 'Update this address in my address book' : 'Save this address in my address book'"
                            v-model="source.save"
                        ></v-checkbox>
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <v-divider vertical/>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="First name" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="First name*"
                            :error-messages="errors"
                            v-model="destination.firstName"/>
                      </ValidationProvider>
                      <ValidationProvider name="Last name" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Last name*"
                            :error-messages="errors"
                            v-model="destination.lastName"/>
                      </ValidationProvider>
                      <ValidationProvider name="Company" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Company*"
                            :error-messages="errors"
                            v-model="destination.company"/>
                      </ValidationProvider>
                      <ValidationProvider name="Email" rules="required|email" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Email*"
                            :error-messages="errors"
                            v-model="destination.email"/>
                      </ValidationProvider>
                      <ValidationProvider name="Phone" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Phone*"
                            :error-messages="errors"
                            v-model="destination.phone"/>
                      </ValidationProvider>
                      <ValidationProvider name="Address 1" rules="required" v-slot="{errors}">
                        <v-text-field
                            dense
                            outlined
                            filled
                            background-color="white"
                            label="Address 1*"
                            :error-messages="errors"
                            v-model="destination.address1"
                        />
                      </ValidationProvider>
                      <v-text-field
                          dense
                          outlined
                          filled
                          background-color="white"
                          label="Address 2"
                          v-model="destination.address2"/>
                      <v-text-field
                          dense
                          outlined
                          filled
                          background-color="white"
                          label="Address 3"
                          v-model="destination.address3"/>
                      <div>
                        <v-checkbox
                            class="float-right"
                            color="orange lighten-5"
                            :label="(shipment.shipToAddressBookId) ? 'Update this address in my address book' : 'Save this address in my address book'"
                            v-model="destination.save"
                        ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                  color="primary"
                  class="mr-3"
                  :disabled="invalid"
                  @click="handleContinue"
              >
                Continue
              </v-btn>
              <v-btn
                  text
                  @click="$emit('back')"
              >
                Back
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <AddressBookModal v-model='addressModal' @select="onAddressSelected"/>
    </v-container>
  </v-stepper-content>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import Country from "@/models/Country";
import Suburb from "@/models/Suburb";
import AddressBookRecord from "@/models/AddressBookRecord";
import AddressBookModal from "@/views/dashboard/pages/modals/AddressBookModal";

export default {
  props: ['complete', 'step'],
  components: {AddressBookModal},
  data() {
    return {
      addressModalType: null,
      addressModal: false,
      source: {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        address3: '',
        suburb: '',
        save: false
      },
      destination: {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        address3: '',
        suburb: '',
        save: false
      }

    }
  },
  computed: {
    ...mapState("shipping", {
      shipment: 'creatingShipment'
    }),
    countryFrom() {
      return Country.find(this.shipment.countryFrom)
    },
    countryTo() {
      return Country.find(this.shipment.countryTo)
    },
    cityFrom() {
      return Suburb.find(this.shipment.cityFrom)
    },
    cityTo() {
      return Suburb.find(this.shipment.cityTo)
    }
  },

  watch: {
    'shipment.shipFromAddressBookId': {
      handler(value) {
        if (value) {
          const address = AddressBookRecord.find(value)
          this.source.firstName = address.contact_name.split(' ')[0];
          this.source.lastName = address.contact_name.split(' ')[1];
          this.source.company = address.company_name;
          this.source.email = address.email;
          this.source.phone = address.phone;
          this.source.address1 = address.street1;
          this.source.address2 = address.street2;
          this.source.address3 = address.street3;
          this.source.suburb = address.suburb;
        }
      }
    },
    'shipment.shipToAddressBookId': {
      handler(value) {
        if (value) {
          const address = AddressBookRecord.find(value)
          this.destination.firstName = address.contact_name.split(' ')[0];
          this.destination.lastName = address.contact_name.split(' ')[1];
          this.destination.company = address.company_name;
          this.destination.email = address.email;
          this.destination.phone = address.phone;
          this.destination.address1 = address.street1;
          this.destination.address2 = address.street2;
          this.destination.address3 = address.street3;
          this.destination.suburb = address.suburb
        }
      }
    }
  },

  methods: {
    ...mapMutations('shipping', {
      updateCreatingShipment: 'updateCreatingShipment'
    }),

    onOpenAddressBook(addressType) {
      this.addressModalType = addressType;
      this.addressModal = true;
    },

    async onAddressSelected(item) {
      await Suburb.api().get(`/api/v1/locations/suburbs/${item.suburb}/`)

      if (this.addressModalType === 'sender') {
        this.updateCreatingShipment({shipFromAddressBookId: item.id})
      } else {
        this.updateCreatingShipment({shipToAddressBookId: item.id})
      }
      this.addressModalType = ''
      this.addressModal = false;
    },

    saveShipFromAddress() {
      const addressBookRecordData = {
        country_code: this.countryFrom.code,
        contact_name: `${this.source.firstName} ${this.source.lastName}`,
        phone: this.source.phone,
        email: this.source.email,
        company_name: this.source.company,
        street1: this.source.address1,
        street2: this.source.address2,
        street3: this.source.address3,
        type: 'sender',
        suburb: this.shipment.cityFrom
      }
      if (this.shipment.shipFromAddressBookId) {
        AddressBookRecord.api().put(`/api/v1/locations/address_book/${this.shipment.shipFromAddressBookId}/`, addressBookRecordData)
      } else {
        AddressBookRecord.api().post(`/api/v1/locations/address_book/`, addressBookRecordData)
      }
    },
    saveShipToAddress() {
      const addressBookRecordData = {
        country_code: this.countryTo.code,
        contact_name: `${this.destination.firstName} ${this.destination.lastName}`,
        phone: this.destination.phone,
        email: this.destination.email,
        company_name: this.destination.company,
        street1: this.destination.address1,
        street2: this.destination.address2,
        street3: this.destination.address3,
        type: 'receiver',
        suburb: this.shipment.cityTo
      }
      if (this.shipment.shipToAddressBookId) {
        AddressBookRecord.api().put(`/api/v1/locations/address_book/${this.shipment.shipToAddressBookId}/`, addressBookRecordData)
      } else {
        AddressBookRecord.api().post(`/api/v1/locations/address_book/`, addressBookRecordData)
      }
    },
    handleContinue() {

      if (this.source.save) {
        this.saveShipFromAddress()
      }

      if (this.destination.save) {
        this.saveShipToAddress()
      }

      this.updateCreatingShipment({
        source: this.source,
        destination: this.destination
      });

      this.$emit('continue')
    }
  }

}
</script>

<style>

</style>
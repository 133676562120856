var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper-content',{attrs:{"step":"2"}},[(_vm.step === 2)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-data-table',{staticClass:"couriers",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.items,"loading":_vm.loading,"loading-text":"Getting quotes...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.courier_logo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.courierLogos[item.shipper_account.courier]}})]}},{key:"item.service_info",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('strong',[_vm._v(_vm._s(item.shipper_account.title))])]),_c('div',[_c('span',[_vm._v(_vm._s(item.service_name))])]),_c('div',[(!item.shipper_account.owned)?_c('v-chip',{attrs:{"x-small":"","label":"","color":"red","text-color":"white"}},[_vm._v(" MyBoxhub ")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.onContinue(item)}}},[_vm._v("Book now")]),_c('p',{},[_c('small',{staticClass:"grey--text"},[_vm._v("£50 GBP Standard Cover* Insurance Included")])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(!!item.fees.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({staticClass:"tooltip"},'strong',attrs,false),on),[_vm._v(_vm._s(item.total_cost)+" "+_vm._s(_vm._f("currency")(item.cost_currency)))])]}}],null,true)},[_vm._l((item.fees),function(item,key){return _c('div',{key:key},[_c('span',[_vm._v(_vm._s(item.title)+": "+_vm._s(item.total_cost))])])}),_c('div',[_vm._v(" Total: "+_vm._s(item.fees.reduce(function (acc, val) { return (acc + parseFloat(val.total_cost)); }, 0).toFixed(2))+" ")])],2):_c('strong',[_vm._v(_vm._s(item.total_cost)+" "+_vm._s(_vm._f("currency")(item.cost_currency)))])]}}],null,false,3813355337)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleBack}},[_vm._v(" Back ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-autocomplete
      outlined
      filled
      background-color="white"
      item-value="id"
      :item-text="postcodeDisplay"
      :items="items"
      :search-input.sync="search"
      :disabled="disabled"
      :loading="isLoading"
      :value="value"
      :name="Math.random()"
      :autocomplete="Math.random()"
      :error-messages="errorMessages"
      @input="setSelected($event)"
      :placeholder="isLoading ? 'Loading...' : ''"
      :no-data-text="isLoading ? 'Loading...' : 'No data available'"
      :label="label"
      hide-no-data
      hide-selected
  />

</template>

<script>
import Suburb from "@/models/Suburb";
import _ from "lodash";
import { CancelToken, isCancel } from 'axios';

let call;
export default {
  name: 'SuburbAutocomplete',
  props: ['value', 'disabled', 'label', 'country', 'errorMessages', 'force'],
  data() {
    return {
      isLoading: false,
      itemsIdList: [],
      search: '',
      selected: null,
    }
  },
  watch: {
    value() {
      if (!this.itemsIdList.find(item => item === this.value)) {
        this.itemsIdList.push(this.value)
      }
    },
    country(val, old) {
      if (this.force) {
        return
      }
      if (val !== null && val !== old) {
        this.loadSuburbsDebounced()
      }
    },
    search(val) {
      if (this.force || !val) {
        return;
      }

      if (this.selected) {
        const selectedDisplayValue = this.postcodeDisplay(this.selected)
        if (val === selectedDisplayValue) {
          return;
        }
      }
      this.loadSuburbsDebounced()
    },
  },
  computed: {
    items() {
      return Suburb.findIn(this.itemsIdList)
    }
  },
  methods: {
    loadSuburbsDebounced: _.debounce( function () { this.fetchSuburbs() }, 500),
    async fetchSuburbs() {
      this.isLoading = true;
      if (call) {
        call.cancel();
      }
      call = CancelToken.source();
      try {
        const res = await Suburb.api().get(
            '/api/v1/locations/suburbs/',
            {
              params: {'country': this.country, search: this.search},
              cancelToken: call.token
            }
        );
        this.itemsIdList = res.getDataFromResponse().map(item => item.id)
        this.isLoading = false;
        this.setSelected('');
      } catch (error) {
        if (!isCancel(error)) {
          throw error
        }
      }
    },
    postcodeDisplay(item) {
      if (item) {
        return `${item.city}` + (item.postal_code ? ` (${item.postal_code})` : ``)
      }
    },
    setSelected(value) {
      this.selected = Suburb.find(value);
      this.$emit('input', value)
      this.isLoading = false;
    }
  }
}
</script>
<template>
  <v-dialog
      v-model="show"
      max-width="800px"
      
  >
    <v-form @reset.prevent="reset">
      <v-card>
        <v-card-title>
          <span class="headline">Address book</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                    label="Search"
                    prepend-icon="mdi-magnify"
                    v-model="search"
                    hint="Input postcode, contact name, street, city etc."
                />
              </v-col>
              <v-col cols="auto">
                <v-autocomplete
                    :items="countries"
                    item-value="code"
                    item-text="title"
                    v-model="countryFilter"
                    :disabled="!!country"
                    :name="Math.random()"
                    :autocomplete="Math.random()"
                    label="Country"/>
              </v-col>
              <v-col cols="auto">
                <v-select
                    :items="addressTypes"
                    item-value="value"
                    item-text="title"
                    v-model="addressTypeFilter"
                    :disabled="!!address_type"
                    :name="Math.random()"
                    :autocomplete="Math.random()"
                    label="Address type"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :page="page"
                  :pageCount="numberOfPages"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  :loading="isLoading"
                  @click:row="onSelect"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="onClose"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AddressBookRecord from "@/models/AddressBookRecord";
import Country from "@/models/Country";

export default {
  props: {
    value: Boolean,
    country: String,
    address_type: String
  },

  data() {
    return {
      search: '',
      items: [],
      countryFilter: this.country,
      isLoading: false,
      selectedAddressId: null,
      page: 1,
      totalItems: 0,
      numberOfPages: 1,
      options: {},
      addressTypes: [
        {title: 'Sender', value: 'sender'},
        {title: 'Receiver', value: 'receiver'},
      ],
      addressTypeFilter: this.address_type,
      headers: [
        {
          text: 'Address',
          value: 'display'
        },
        {
          text: 'Contact name',
          value: 'contact_name'
        },
        {
          text: 'Company',
          value: 'company'
        },
        {
          text: 'Phone',
          value: 'phone'
        },
      ]
    }
  },
  watch: {
    countryFilter() {
      this.options.page = 1;
      this.loadFromAddressBook();
    },
    addressTypeFilter() {
      this.options.page = 1;
      this.loadFromAddressBook();
    },
    search(searchValue) {
      const selectedValue = this.items.find(item => item.id === this.selectedAddressId)
      if (searchValue && searchValue !== selectedValue) {
        this.options.page = 1
        this.loadFromAddressBook()
      }
    },
    options: {
      handler() {
        this.loadFromAddressBook();
      },
      deep: true
    },
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    countries() {
      const countries = Country.all();

      countries.sort((a, b) => {
        // Make GB first
        return a.code === 'GB' ? -1 : b.code === 'GB' ? 1 : 0;
      })
      return countries
    },
  },
  methods: {
    onClose() {
      this.show = false
    },
    onSelect(item) {
      this.$emit('select', item)
      this.onClose()
    },
    async loadFromAddressBook() {
      this.isLoading = true;
      const { page, itemsPerPage } = this.options;
      const res = await AddressBookRecord.api().get(
          '/api/v1/locations/address_book/',
          {
            params: {
              country: this.country || this.countryFilter,
              search: this.search,
              type: this.addressTypeFilter,
              page: page,
              page_size: itemsPerPage || 10
            },
          }
      )
      this.totalItems = res.response.data.count;
      this.numberOfPages = res.response.data.total_pages;
      this.items = res.getDataFromResponse()
      this.isLoading = false
    },
  }
}
</script>
<template>
  <v-container
      id="regular-tables"
      fluid
      tag="section"
  >
    <v-card>
      <v-stepper
          v-model="step"
          vertical
      >
        <v-stepper-step
            :complete="step > 1"
            step="1"
        >
          Shipment information
          <small>Source and destination, packages info</small>
        </v-stepper-step>
        <StepOneShipmentInformation
            :complete="step > 1"
            :step="step"
            @continue="handleContinue"
            @back="handleBack"/>

        <v-stepper-step
            :complete="step > 1"
            step="2"
        >
          Select courier service
        </v-stepper-step>
        <StepTwoCourierServices
            :complete="step > 2"
            :step="step"
            @continue="handleContinue"
            @back="handleBack"/>

        <v-stepper-step
            :complete="step > 3"
            step="3"
        >
          Address details
          <small>Sender and receiver</small>
        </v-stepper-step>

        <StepThreeAddressDetails
            :complete="step > 3"
            :step="step"
            @continue="handleContinue"
            @back="handleBack"/>

        <v-stepper-step
            :complete="step > 3"
            step="4">
          Package details
          <small>Contents and customs details</small>
        </v-stepper-step>

        <StepFourPackageDetails
            :complete="step > 4"
            :step="step"
            @continue="handleContinue"
            @back="handleBack"/>

        <v-stepper-step step="5">
          Payment
        </v-stepper-step>
        <StepFivePayment
            :complete="step > 5"
            :step="step"
            @continue="handleContinue"
            @back="handleBack"/>
      </v-stepper>
    </v-card>
  </v-container>
</template>


<script>
import StepOneShipmentInformation from './StepOneShipmentInformation';
import StepTwoCourierServices from './StepTwoCourierServices';
import StepThreeAddressDetails from './StepThreeAddressDetails';
import StepFourPackageDetails from './StepFourPackageDetails';
import StepFivePayment from './StepFivePayment';
import ShippingPackage from "@/models/ShippingPackage";


export default {
  components: {
    StepOneShipmentInformation,
    StepTwoCourierServices,
    StepThreeAddressDetails,
    StepFourPackageDetails,
    StepFivePayment,
  },
  data() {
    return {
      step: 1
    }
  },
  methods: {
    handleContinue() {
      this.step += 1;
    },
    handleBack() {
      this.step -= 1;
    }
  },
  async mounted() {
    await ShippingPackage.api().get('/api/v1/shipping/shipping_packages/')
  }
}
</script>

import { Model } from '@vuex-orm/core'


class ParcelItem extends Model {
    static entity = 'parcelItem'

    static fields () {
        return {
            id: this.attr(null),
            parcel: this.attr(''),
            description: this.attr(''),
            quantity: this.attr(''),
            price: this.attr(''),
            origin_country: this.attr(''),
            hs_code: this.attr(''),
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        }
    }
}

export default ParcelItem

<template>
  <v-stepper-content step="2">
    <v-container fluid v-if="step === 2">
      <v-row>
        <v-col cols="auto">
          <v-data-table
              :headers="headers"
              :items="loading ? [] : items"
              :loading="loading"
              loading-text="Getting quotes..."
              hide-default-footer
              class="couriers"
          >
            <template v-slot:item.courier_logo="{ item }">
              <v-img :src="courierLogos[item.shipper_account.courier]"></v-img>
            </template>
            <template v-slot:item.service_info="{ item }">
              <div>
                <strong>{{item.shipper_account.title}}</strong>
              </div>
              <div><span>{{ item.service_name }}</span></div>
              <div>
                <v-chip
                    x-small
                    label
                    color="red"
                    text-color="white"
                    v-if="!item.shipper_account.owned"
                >
                  MyBoxhub
                </v-chip>
              </div>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn color="primary" @click="onContinue(item)" large class="mt-5">Book now</v-btn>
              <p class=""><small class="grey--text">£50 GBP Standard Cover* Insurance Included</small></p>
            </template>
            <template v-slot:item.price="{item}">
              <v-tooltip bottom v-if="!!item.fees.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <strong class="tooltip" v-bind="attrs" v-on="on">{{item.total_cost}} {{item.cost_currency | currency}}</strong>
                </template>
                <div v-for="(item, key) in item.fees" :key="key">
                  <span>{{item.title}}: {{item.total_cost}}</span>
                </div>
                <div>
                  Total: {{ item.fees.reduce((acc, val) => (acc + parseFloat(val.total_cost)), 0).toFixed(2) }}
                </div>
              </v-tooltip>
              <strong v-else>{{item.total_cost}} {{item.cost_currency | currency}}</strong>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn text @click="handleBack">
            Back
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-stepper-content>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import Rate from "@/models/Rate";
import { courierLogos } from "@/choices";

export default {
  props: ['complete', 'step'],
  data: function () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Courier',
          value: 'courier_logo'
        },
        {
          sortable: false,
          text: 'Service',
          value: 'service_info'
        },
        {
          sortable: false,
          text: 'Transit days',
          value: 'transit_days'
        },
        {
          sortable: false,
          text: 'Price',
          value: 'price',
          align: 'center'
        },
        {
          sortable: false,
          text: '',
          value: 'actions',
          align: 'center'
        }
      ],
      loading: true,
      courierLogos
    }
  },
  computed: {
    ...mapState('shipping', {
      shipment: 'creatingShipment',
    }),
    items() {
      return Rate.query().with('shipper_account').all();
    }
  },
  watch: {
    step: function (newVal) {
      if (newVal === 2) {
        this.getRates(this.shipment)
      }
    }
  },
  methods: {
    ...mapMutations('shipping', {
      updateCreatingShipment: 'updateCreatingShipment'
    }),

    onContinue(selectedRate) {
      this.updateCreatingShipment({selectedRate: selectedRate.id})
      this.$emit('continue', {selectedRate})
    },

    async getRates(shipment) {
      await Rate.deleteAll()
      await Rate.api().calculateRates(
          {
            shipFrom: shipment.cityFrom,
            shipTo: shipment.cityTo,
            packages: shipment.packages,
            termsOfTrade: shipment.termsOfTrade
          }
      );

      this.loading = false;
    },

    handleBack() {
      this.loading = true;
      this.$emit('back')
    }
  }
}
</script>

<style scoped>

  .couriers td, .couriers th  {
    font-size: 1.2rem;
  }

  .tooltip {
    border-bottom: 1px dashed;
  }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('h3',{staticClass:"font-weight-light"},[_vm._v("Package #"+_vm._s(_vm.index + 1)+" "+_vm._s(_vm.box.width)+"x"+_vm._s(_vm.box.depth)+"x"+_vm._s(_vm.box.height)+" "+_vm._s(_vm.box.weight)+"kg")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"dense":"","items":_vm.filteredProductItems,"item-text":"description","item-value":"id","error-messages":errors},on:{"change":function($event){_vm.productSelected(item)($event)},"keyup":_vm.filterParcelItems}})]}}],null,true)})]}},(!_vm.isDomestic)?{key:"item.hs_code",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"hs_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","error-messages":errors},model:{value:(item.hs_code),callback:function ($$v) {_vm.$set(item, "hs_code", $$v)},expression:"item.hs_code"}})]}}],null,true)})]}}:null,{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","error-messages":errors,"type":"number"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}}],null,true)})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","suffix":_vm._f("currency")(_vm.currency),"error-messages":errors,"type":"number"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})]}},{key:"item.total_value",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":"","dense":"","value":(item.quantity * item.value).toFixed(2),"suffix":_vm._f("currency")(_vm.currency)}})]}},(!_vm.isDomestic)?{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","error-messages":errors},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})]}}],null,true)})]}}:null,(!_vm.isDomestic)?{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","items":_vm.countries,"item-value":"code","item-text":"title","error-messages":errors},model:{value:(item.country),callback:function ($$v) {_vm.$set(item, "country", $$v)},expression:"item.country"}})]}}],null,true)})]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{style:({visibility: (_vm.items.length > 1) ? 'visible' : 'hidden'}),attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-5",attrs:{"small":""},on:{"click":_vm.addItem}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add item ")],1)],1)]},proxy:true}],null,true)})],1),_c('v-divider',{staticClass:"my-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
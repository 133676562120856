<template>
  <v-stepper-content step="4">
    <v-container fluid v-if="step === 4">
      <ValidationObserver v-slot="{invalid}">
        <v-row>
          <v-col cols="12" lg=9 xl="6">
            <v-row v-if="isPickupSupported">
              <v-col>
                <div class="d-flex flex-column mx-auto">
                  <v-radio-group
                      row
                      v-model="form.pickup"
                  >
                    <v-radio
                        label="Pick Up"
                        value="pick-up"
                    ></v-radio>
                    <v-radio
                        label="Drop Off"
                        value="drop-off"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col class="d-flex flex-column align-content-center" v-if="showPickupWidget">
                <div class="d-flex flex-column mx-auto">
                  <ValidationProvider name="pickupDate" rules="required"
                                      v-slot="{errors}">
                    <v-date-picker
                        class="mt-4"
                        v-model="form.date"
                        :min="new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString()"/>
                    <span v-if="errors.length > 0" class="error--text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="auto" v-if="showPickupWidget">
                <v-divider vertical/>
              </v-col>
              <v-col class="d-flex  flex-column align-content-center justify-center"  v-if="showPickupWidget">
                <div>
                  <h3 class="font-weight-regular mb-3">Pick up time:</h3>
                  <ValidationProvider
                      name="pickupEarliest"
                      :rules="{required: showPickupWidget}"
                      v-slot="{errors}"
                  >
                    <v-autocomplete
                        dense
                        outlined
                        label="Earliest collection time"
                        :items="earliestCollectionTimeChoices"
                        :disabled="form.pickup === 'drop-off'"
                        :error-messages="errors"
                        v-model="form.pickupEarliest"
                        item-value="value"
                        item-text="title"/>
                  </ValidationProvider>
                  <ValidationProvider
                      name="pickupLatest"
                      :rules="{required: showPickupWidget}"
                      v-slot="{errors}"
                  >
                    <v-autocomplete
                        dense
                        outlined
                        label="Latest collection time"
                        :items="latestCollectionTimeChoices"
                        :disabled="form.pickup === 'drop-off'"
                        :error-messages="errors"
                        v-model="form.pickupLatest"
                        item-value="value"
                        item-text="title"/>
                  </ValidationProvider>
                  <div class="text-center">
                    <small class="grey--text">You must provide a minimum of 2 hours for pickup</small>
                  </div>
                </div>
              </v-col>
              <v-col cols="auto" v-if="showPickupWidget">
                <v-divider vertical/>
              </v-col>
              <v-col class="d-flex justify-center align-center flex-column">
                <h2 class="my-5 text-center font-weight-regular text-no-wrap">Questions?</h2>
                <p>Do you have any questions about how to complete your booking? Call us now for assistance on the
                  telephone number displayed below.</p>
                <h2 class="my-5 text-center font-weight-regular text-no-wrap blue--text">08444 930 694</h2>
              </v-col>
            </v-row>

            <h2 class="text-center my-5 font-weight-regular">Package contents</h2>
            <v-row>
              <v-col>
                <ValidationProvider name="reasonOfExport" rules="required" v-slot="{errors}" v-if="!isDomestic && !isDocument">
                  <v-autocomplete
                      outlined
                      dense
                      label="Reason of export"
                      item-text="title"
                      item-value="value"
                      v-model="form.reasonOfExport"
                      :error-messages="errors"
                      :items="reasonOfExportItems"/>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="taxStatus" rules="required" v-slot="{errors}" v-if="!isDomestic && !isDocument">
                  <v-autocomplete
                      outlined
                      dense
                      label="Tax status"
                      item-text="title"
                      item-value="value"
                      v-model="form.taxStatus"
                      :items="taxStatusItems"
                      :error-messages="errors"/>
                </ValidationProvider>
              </v-col>
              <v-col>
                <v-autocomplete
                    outlined
                    dense
                    readonly
                    disabled
                    hint="You can change it on the first step"
                    persistent-hint
                    label="Terms of trade"
                    item-text="title"
                    item-value="value"
                    v-model="form.termsOfTrade"
                    :items="termsOfTradeItems"
                    v-if="!isDomestic && !isDocument"
                />
              </v-col>
            </v-row>

            <ValidationProvider name="agree" rules="required" v-slot="{errors}">
              <v-checkbox v-model="agree" :error-messages="errors">
                <template v-slot:label>
                  <div>
                    I have read and understood the content of the
                    <a href="#">prohibited items page</a>
                    and agree not to ship any of the prohibited items specified.
                  </div>
                </template>
              </v-checkbox>
            </ValidationProvider>

            <v-card class="warning lighten-1">
              <v-card-title style="font-size: 1.3rem" class="text-center font-weight-light mb-5 pt-5 justify-center">
                Please provide us with a detailed list of the items contained in your packages.
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Description" rules="required" v-slot="{errors}">
                      <v-text-field
                          dense
                          persistent-hint
                          label="Brief description of goods"
                          hint="Please enter a brief description of the goods you're sending. e.g. &quot;computer equipment&quot; or &quot;clothing&quot;"
                          :error-messages="errors"
                          v-model="form.description"
                          v-if="!isDocument"
                      />
                      <v-combobox
                          dense
                          persistent-hint
                          label="Brief description of goods"
                          hint="Please select a description of the goods you're sending"
                          :error-messages="errors"
                          hide-no-data
                          v-model="form.description"
                          :items="documentChoices"
                          v-else
                      >
                      </v-combobox>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="auto">
                    <v-select
                        dense
                        persistent-hint
                        :items="currencyItems"
                        hint="Currency of goods declared value"
                        @change="setSelectedCurrency"
                        v-model="selectedCurrency"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <template v-if="!isDocument">
                <div v-for="(item, index) in shipment.packages" :key="index">
                  <PackageContent
                      :index="index"
                      :currency="selectedCurrency"
                      :box="item"/>
                </div>
              </template>
              <v-card-text class="text-center">
                <p>The amount you declare is used for insurance purposes and must reflect the true and accurate
                  amount.</p>
                <p>You must include any duty that may be applicable.</p>
                <p>NOTE: Misrepresentation of the value of items may result in fines being imposed by custom authorities
                  and will prejudice your insurance.</p>
              </v-card-text>
            </v-card>
            <v-textarea
                label="Comments (Optional)"
                outlined
                class="mt-5"
                hint="If you'd like to add any further description or comments related to the items in your package(s), please enter them below."
                persistent-hint
                v-model="form.comments"/>

            <h2 class="text-center my-5 font-weight-regular">Insurance</h2>
            <p class="text-center">All consignments are automatically covered up to £50.</p>
            <div class="d-flex flex-column justify-center">
              <v-radio-group
                  class="mx-auto"
                  row
                  v-model="form.insurance"
              >
                <v-radio
                    label="No thanks, Standard cover is fine."
                    :value="false"
                ></v-radio>
                <v-radio
                    label="Yes, please cover my item for £15.00"
                    :value="true"
                ></v-radio>
              </v-radio-group>
              <p class="grey--text text-center">
                <small>To find more about the insurance options available to you select the following link. <a href="#">
                  Insurance options.</a></small>
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
                color="primary"
                class="mr-3"
                :disabled="invalid"
                @click="handleContinue"
            >
              Continue
            </v-btn>
            <v-btn
                text
                @click="$emit('back')"
            >
              Back
            </v-btn>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-container>
  </v-stepper-content>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex';
import Country from "@/models/Country";
import PackageContent from "@/views/dashboard/pages/CreateShipment/PackageContent";
import {pickUpTimeEarliest, pickUpTimeLatest, purpose, termsOfTrade} from '@/choices';
import Rate from "../../../../models/Rate";
import ShipperAccount from "../../../../models/ShipperAccount";

export default {
  components: {PackageContent},
  props: ['complete', 'step'],
  data: function () {
    return {
      form: {
        date: '',
        pickup: 'pick-up',
        pickupEarliest: '',
        pickupLatest: '',
        description: '',
        reasonOfExport: '',
        termsOfTrade: this.$store.state.shipping.creatingShipment.termsOfTrade || 'ddu',
        taxStatus: '',
        comments: '',
        insurance: false,
      },
      currencyItems: [
          'GBP',
          'EUR',
          'USD'
      ],
      selectedCurrency: 'GBP',
      agree: false,
      earliestCollectionTimeChoices: pickUpTimeEarliest,
      latestCollectionTimeChoices: pickUpTimeLatest,
      reasonOfExportItems: purpose,
      termsOfTradeItems: termsOfTrade,
      taxStatusItems: [
        {value: 'private', title: 'Private'},
        {value: 'company', title: 'Company'},
      ],
      documentChoices: [
        "General Business Documents",
        "Legal Deeds",
        "Visa Applications",
        "Accounting Documents",
        "Proposal Documentation",
      ]
    }
  },
  computed: {
    ...mapState("shipping", {shipment: 'creatingShipment'}),
    ...mapGetters("shipping", ["isDocument", "isDomestic"]),
    countries() {
      return Country.all();
    },
    selectedRate() {
      console.log(this.shipment.selectedRate)
      return Rate.find(this.shipment.selectedRate)
    },
    selectedCourier() {
      console.log(this.selectedRate)
      return ShipperAccount.find(this.selectedRate.shipper_account_id)
    },
    isPickupSupported() {
      console.log(this.selectedCourier)
      console.log(this.selectedCourier.courier !== 'tnt')
      if (this.selectedCourier && this.selectedCourier.courier === 'tnt') {
        return false
      }
      return true
    },
    showPickupWidget() {
      return this.form.pickup === 'pick-up' && this.isPickupSupported
    }
  },
  methods: {
    ...mapMutations('shipping', {updateCreatingShipment: 'updateCreatingShipment'}),
    handleContinue() {
      this.updateCreatingShipment(this.form);
      this.$emit('continue')
    },
    setSelectedCurrency(value) {
      this.updateCreatingShipment({
        packages:this.shipment.packages.map(
            box => (
                {...box, items: box.items?.map(item => (
                    {...item, price_currency: value}
                ))
            }))
        }
      )
    }
  },
}
</script>

<style>

</style>
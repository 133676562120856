<template>
  <div>
    <v-card-text>
      <h3 class="font-weight-light">Package #{{ index + 1 }}
        {{ box.width }}x{{ box.depth }}x{{ box.height }} {{ box.weight }}kg</h3>
    </v-card-text>
    <v-card-text>
      <v-data-table
          :headers="filteredHeaders"
          :items="items"
          hide-default-footer
      >
        <template v-slot:item.description="{ item }">
          <ValidationProvider name="description" rules="required" v-slot="{errors}">
            <v-combobox
                dense
                :items="filteredProductItems"
                item-text="description"
                item-value="id"
                @change="productSelected(item)($event)"
                :error-messages="errors"
                @keyup="filterParcelItems"
            />
<!--            <v-text-field dense v-model="item.description" :error-messages="errors" />-->
          </ValidationProvider>
        </template>

        <template v-slot:item.hs_code="{ item }" v-if="!isDomestic">
          <ValidationProvider name="hs_code" rules="required" v-slot="{errors}">
            <v-text-field dense v-model="item.hs_code" :error-messages="errors" />
          </ValidationProvider>
        </template>
        <template v-slot:item.quantity="{ item }">
          <ValidationProvider name="quantity" rules="required" v-slot="{errors}">
            <v-text-field dense v-model="item.quantity" :error-messages="errors" type="number"/>
          </ValidationProvider>
        </template>
        <template v-slot:item.value="{ item }">
          <ValidationProvider name="value" rules="required" v-slot="{errors}">
            <v-text-field dense v-model="item.value" :suffix="currency | currency" :error-messages="errors" type="number"/>
          </ValidationProvider>
        </template>
        <template v-slot:item.total_value="{ item }">
          <v-text-field disabled dense :value="(item.quantity * item.value).toFixed(2)" :suffix="currency | currency"></v-text-field>
        </template>
        <template v-slot:item.weight="{ item }" v-if="!isDomestic">
          <ValidationProvider name="quantity" rules="required" v-slot="{errors}">
            <v-text-field dense v-model="item.weight" type="number" :error-messages="errors" />
          </ValidationProvider>
        </template>
        <template v-slot:item.country="{ item }" v-if="!isDomestic">
          <ValidationProvider name="country" rules="required" v-slot="{errors}">
            <v-autocomplete dense v-model="item.country" :items="countries" item-value="code" item-text="title" :error-messages="errors"/>
          </ValidationProvider>
        </template>
        <template v-slot:item.actions="{item}">
          <v-btn icon :style="{visibility: (items.length > 1) ? 'visible' : 'hidden'}" @click="deleteItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-end">
            <v-btn small class="ma-5" @click="addItem">
              <v-icon>mdi-plus</v-icon>
              Add item
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider class="my-5"/>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters, mapMutations} from 'vuex';
import Country from "@/models/Country";
import ParcelItem from "@/models/ParcelItem";

export default {
  name: 'PackageContent',
  props: ['index', 'box', 'currency'],

  data() {
    return {
      headers: [
        {
          sortable: false,
          text: 'Item description',
          value: 'description',
          international: false,
        },
        {
          sortable: false,
          text: 'HS code',
          value: 'hs_code',
          international: true,
        },
        {
          sortable: false,
          text: 'Quantity',
          value: 'quantity',
          international: false,
        },
        {
          sortable: false,
          text: 'Value of each',
          value: 'value',
          international: false,
        },
        {
          sortable: false,
          text: 'Total value',
          value: 'total_value',
          international: false,
        },
        {
          sortable: false,
          text: 'Weight',
          value: 'weight',
          international: true,
        },
        {
          sortable: false,
          text: 'Country of manufacture',
          value: 'country',
          international: true,
        },
        {
          sortable: false,
          text: '',
          value: 'actions'
        }
      ],
      productSearch: '',
      productItems: [],
      items: [
        {
          description: '',
          quantity: '',
          value: '',
          hs_code: '',
          total_value: '',
          country: '',
        }
      ],
    }
  },

  computed: {
    ...mapGetters("shipping", ["isDomestic"]),
    countries() {
      return Country.all()
    },
    filteredProductItems() {
      return _.uniqBy(this.productItems, 'description')
    },
    filteredHeaders() {
      if (this.isDomestic) {
        return this.headers.filter(item => !item.international)
      }
      return this.headers
    }
  },

  async mounted() {
    await this.fetchParcelItems('')
  },

  watch: {
    items: {
      handler(value) {
        this.setPackageItems({
          packageIndex: this.index,
          items: [...value.map(item => ({...item, price_currency: this.currency}))]
        })
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations("shipping", {
      setPackageItems: 'setPackageItems'
    }),
    addItem() {
      this.items.push({
        description: '',
        quantity: '',
        hs_code: '',
        value: '',
        total_value: '',
        country: '',
        weight: ''
      })
    },
    deleteItem(item) {
      const index = this.items.indexOf(item)
      this.items.splice(index, 1)
    },
    productSelected(item) {
      return (selectedValue) => {
        if (selectedValue.id) {
          item.value = selectedValue.price
          item.country = selectedValue.origin_country
          item.description = selectedValue.description
          item.hs_code = selectedValue.hs_code
        } else {
          item.description = selectedValue
        }
      }
    },
    async filterParcelItems(event) {
      const value = event.target.value
      await this.fetchParcelItems(value)
    },

    async fetchParcelItems(value) {
      const res = await ParcelItem.api().get('/api/v1/shipping/items/', {params: {search: value}, save: false})
      this.productItems = res.getDataFromResponse()
    }
  }
}
</script>
<template>
  <v-stepper-content step="1">
    <v-container fluid v-if="step===1">
      <ValidationObserver v-slot="{invalid}">
        <v-form @submit.prevent="onSubmit" :autocomplete="Math.random()">
          <v-row>
            <v-col cols="auto">
              <v-card class="warning lighten-1 mb-5">
                <v-card-text>
                  <v-row>
                    <v-col cols="6" class="text-right">
                      <v-btn small color="warning" @click="onOpenAddressBook('sender')">
                        <v-icon small class="mr-2">mdi-book-open-variant</v-icon>
                        From address book
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn small color="warning" @click="onOpenAddressBook('receiver')">
                        <v-icon small class="mr-2">mdi-book-open-variant</v-icon>
                        From address book
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="countryFrom" rules="required" v-slot="{errors}">
                        <v-autocomplete
                            :items="countries"
                            item-value="code"
                            item-text="title"
                            v-model="form.countryFrom"
                            outlined
                            filled
                            background-color="white"
                            :error-messages="errors"
                            :autocomplete="Math.random()"
                            :name="Math.random()"
                            :disabled="!!shipFromSelectedFromAddressBook"
                            label="Country from"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="cityFrom" rules="required" v-slot="{errors}">
                        <SuburbAutocomplete
                            :country="form.countryFrom"
                            :disabled="!form.countryFrom || !!shipFromSelectedFromAddressBook"
                            v-model="form.cityFrom"
                            :error-messages="errors"
                            :force="!!shipFromSelectedFromAddressBook"
                            label="City from"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="auto" v-if="shipFromSelectedFromAddressBook">
                      <v-btn icon class="mt-3" @click="clearAddress('sender')"><v-icon>mdi-close-thick</v-icon></v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-divider vertical/>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="countryTo" rules="required" v-slot="{errors}">
                        <v-autocomplete
                            :items="countries"
                            item-text="title"
                            item-value="code"
                            v-model="form.countryTo"
                            outlined
                            filled
                            :autocomplete="Math.random()"
                            :name="Math.random()"
                            background-color="white"
                            :error-messages="errors"
                            :disabled="!!shipToSelectedFromAddressBook"
                            label="Country to"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="cityTo" rules="required" v-slot="{errors}">
                        <SuburbAutocomplete
                            :country="form.countryTo"
                            :disabled="!form.countryTo || !!shipToSelectedFromAddressBook"
                            v-model="form.cityTo"
                            :error-messages="errors"
                            :force="!!shipToSelectedFromAddressBook"
                            label="City to"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="auto" v-if="shipToSelectedFromAddressBook">
                      <v-btn icon class="mt-3" @click="clearAddress('receiver')"><v-icon>mdi-close-thick</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="warning lighten-1 mb-5">
                <v-card-text>
                  <h3 class="font-weight-light">Packages</h3>
                </v-card-text>
                <v-card-text>
                  <v-row align="baseline" v-for="(item, index) in packages" :key="index">
                    <v-col cols="auto">
                      <span class="font-weight-bold">{{ index + 1}}</span>
                    </v-col>
                    <v-col cols="auto">
                      <v-chip label>kg/cm</v-chip>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="length" rules="required|numeric" v-slot="{errors}">
                        <v-text-field
                            outlined
                            label="Length"
                            background-color="white"
                            dense
                            v-model="item.depth"
                            :error-messages="errors"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="width" rules="required|numeric" v-slot="{errors}">
                        <v-text-field
                            outlined
                            label="Width"
                            background-color="white"
                            dense
                            v-model="item.width"
                            :error-messages="errors"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="height" rules="required|numeric" v-slot="{errors}">
                        <v-text-field
                            outlined
                            label="Height"
                            background-color="white"
                            dense
                            v-model="item.height"
                            :error-messages="errors"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="weight" rules="required" v-slot="{errors}">
                        <v-text-field
                            outlined
                            label="Weight"
                            background-color="white"
                            dense
                            v-model="item.weight"
                            :error-messages="errors"/>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        :disabled="numberOfPackages > 1"
                        label="Is document"
                        class="mt-1"
                        v-model="item.is_document"
                        color="orange lighten-5"
                      />
                    </v-col>
                    <v-col>
                      <v-autocomplete
                          v-model="item.shipping_package"
                          v-if="shippingPackages"
                          @change="populateDimensions(item)"
                          label="Shipping package"
                          :items="shippingPackages"
                          item-value="id"
                          item-text="title"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-tooltip top @click="fillBottomPackages(index)">
                        <template v-slot:activator="{}">
                          <v-btn icon
                                 :style="{visibility: (index < packages.length - 1 && packages.length > 1) ? 'visible' : 'hidden'}"
                                 @click="fillBottomPackages(index)">
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy values to the bottom package</span>
                      </v-tooltip>
                      <v-tooltip top @click="fillBottomPackages(index, true)">
                        <template v-slot:activator="{}">
                          <v-btn icon
                                 :style="{visibility: (index < packages.length - 1 && packages.length > 1) ? 'visible' : 'hidden'}"
                                 @click="fillBottomPackages(index, true)">
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy values to all bottom packages</span>
                      </v-tooltip>
                      <v-btn icon @click="removePackage(index)"
                             :style="{visibility: index > 0 ? 'visible' : 'hidden'}">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-row align="baseline">
                    <v-col cols="12">
                      <v-divider/>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        :disabled="containDocument"
                        class="float-right"
                        v-model="numberOfPackages"
                        :items="addPackageItems"
                        outlined
                        hide-no-data
                        item-text="title"
                        item-value="count"
                        dense
                        hide-details
                        @change="packagesChange"
                        label="Number of packages"
                        />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="warning lighten-1" v-if="showOptionsBlock">
                <v-card-text>
                  <h3 class="font-weight-light">Options</h3>
                </v-card-text>
                <v-card-text>
                  <v-row>
                    <v-col cols="auto">
                      <v-autocomplete
                          dense
                          filled
                          background-color="white"
                          outlined
                          label="Terms of trade"
                          item-text="title"
                          item-value="value"
                          v-model="form.termsOfTrade"
                          :items="termsOfTradeItems"
                          v-if="showTermsOfTrade"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                  color="primary"
                  class="mr-3"
                  type="submit"
                  :disabled="invalid"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <AddressBookModal v-model='addressModal' @select="onAddressSelected"/>
    </v-container>
  </v-stepper-content>

</template>

<script>

import Country from "@/models/Country";
import SuburbAutocomplete from "./SuburbAutocomplete";
import {mapGetters, mapMutations} from 'vuex';
import ShippingPackage from "@/models/ShippingPackage";
import AddressBookModal from "@/views/dashboard/pages/modals/AddressBookModal";
import Suburb from "@/models/Suburb";
import {termsOfTrade} from '@/choices';


const pluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;


export default {
  props: ['complete', 'step'],
  components: {
    AddressBookModal,
    SuburbAutocomplete
  },
  data: function () {
    return {
      numberOfPackages: 1,
      addressModal: false,
      termsOfTradeItems: termsOfTrade,
      addressModalType: '',
      form: {
        countryFrom: '',
        cityFrom: '',
        countryTo: '',
        cityTo: '',
        termsOfTrade: 'ddu'
      },
      shipFromSelectedFromAddressBook: null,
      shipToSelectedFromAddressBook: null,
      packages: [{
        is_document: false,
      }],
      addPackageItems: [
        ...Array(15).keys()].map(
            (packageNumber) => ({
              title: pluralize(packageNumber + 1, 'package'),
              count: packageNumber + 1
            })
        )
    }
  },
  computed: {
    ...mapGetters("shipping", ["isDocument", "isDomestic"]),
    countries() {
      const countries = Country.all();

      countries.sort((a, b) => {
        // Make GB first
        return a.code === 'GB' ? -1 : b.code === 'GB' ? 1 : 0;
      })
      return countries
    },
    shippingPackages() {
      return ShippingPackage.all()
    },
    containDocument() {
      return !!this.packages.find((pack) => pack.is_document)
    },
    showTermsOfTrade() {
      return this.form.countryFrom && this.form.countryTo && !this.isDomestic && !this.isDocument
    },
    showOptionsBlock() {
      return this.showTermsOfTrade
    }
  },
  watch: {
    form: {
      handler: function (value) {
        this.updateCreatingShipment(value)
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      updateCreatingShipment: 'shipping/updateCreatingShipment'
    }),
    onOpenAddressBook(addressType) {
      this.addressModalType = addressType;
      this.addressModal = true;
    },
    async onAddressSelected(item) {
      await Suburb.api().get(`/api/v1/locations/suburbs/${item.suburb}/`)

      if (this.addressModalType === 'sender') {
        this.form.countryFrom = item.country;
        this.form.cityFrom = item.suburb;
        this.shipFromSelectedFromAddressBook = item.id;
      } else {
        this.form.countryTo = item.country;
        this.form.cityTo = item.suburb;
        this.shipToSelectedFromAddressBook = item.id;
      }
      this.addressModalType = ''
      this.addressModal = false;
    },
    packagesChange() {
      const delta = parseInt(this.numberOfPackages) - this.packages.length;

      if (delta > 0) {
        this.packages = [
            ...this.packages.concat([...Array(delta).keys()].map(() => ({is_document: false})))
        ];
      } else {
        this.packages = [...this.packages.slice(Math.abs(delta))]
      }
    },
    fillBottomPackages(copyFromIndex, all = false) {
      const fillToIndex = (all) ? this.packages.length - 1 : copyFromIndex + 1
      this.packages = this.packages.map((item, idx) => (idx > copyFromIndex && idx <= fillToIndex) ? {...this.packages[copyFromIndex]} : item)
    },
    removePackage(index) {
      this.packages.splice(index, 1)
      this.numberOfPackages = parseInt(this.numberOfPackages) - 1
    },
    onSubmit() {
      this.updateCreatingShipment({
        ...this.form,
        packages: this.packages,
        shipFromAddressBookId: this.shipFromSelectedFromAddressBook,
        shipToAddressBookId: this.shipToSelectedFromAddressBook
      });
      this.$emit('continue')
    },
    clearAddress(addressType) {
      if (addressType === 'sender') {
        this.form.countryFrom = ''
        this.form.cityFrom = ''
        this.shipFromSelectedFromAddressBook = null;
      }
      if (addressType === 'receiver') {
        this.form.countryTo = ''
        this.form.cityTo = ''
        this.shipToSelectedFromAddressBook = null;
      }

    },
    populateDimensions(box) {
      const shippingPackage = ShippingPackage.find(box.shipping_package)
      box.width = parseInt(shippingPackage.width)
      box.height = parseInt(shippingPackage.height)
      box.depth = parseInt(shippingPackage.depth)
      box.is_document = shippingPackage.is_document
      box.weight = shippingPackage.weight
    }
  }
}

</script>
